const order = ['CORE', 'SIGNIFICANT', 'PERIPHERAL', null];

export function sortIndustriesByRelevance(industries) {
  return [...industries].sort((a, b) => {
    const indexA = order.indexOf(a.industryRelevance);
    const indexB = order.indexOf(b.industryRelevance);
    return indexA - indexB;
  });
}

export function sortCountriesByRelevance(countries) {
  return [...countries].sort((a, b) => {
    const indexA = order.indexOf(a.locationRelevance);
    const indexB = order.indexOf(b.locationRelevance);
    return indexA - indexB;
  });
}
