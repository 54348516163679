import { gql } from '@apollo/client';

const query = gql`
  query getFeed(
    $topics: [ID]
    $entityIds: [ID]
    $industryIds: [ID]
    $updatedInLastDays: Int
    $filter: [FeedType]
    $regulationTypes: [FeedRegulationType]
    $includeBookmarks: Boolean
    $includeConnections: Boolean
    $includeNotes: Boolean
    $includeCompliance: Boolean
    $limit: Int
    $offset: Int
  ) {
    getFeed(
      topics: $topics
      entityIds: $entityIds
      industryIds: $industryIds
      updatedInLastDays: $updatedInLastDays
      filter: $filter
      regulationTypes: $regulationTypes
      includeBookmarks: $includeBookmarks
      includeConnections: $includeConnections
      includeNotes: $includeNotes
      includeCompliance: $includeCompliance
      limit: $limit
      offset: $offset
    ) {
      total {
        type
        count
      }
      updates {
        __typename
        ... on FeedEvent {
          id
          sources
          title
          date
          notesCount
          summary
          bullets
          sentiment
          magnitude
          language
          entities {
            id
            name
            type
            image
            inSubscription
            isConnected
            shortestName
            tooltip
          }
          industries {
            id
            name
            type
            tooltip
            industryRelevance
          }
          countries {
            id
            name
            locationRelevance
          }
          landscapes {
            id
            name
          }
          quotes {
            id
            quote
            context
            speaker {
              id
              name
              shortestName
              image
              type
              inSubscription
              isConnected
              tooltip
            }
          }
        }
        ... on FeedQuote {
          id
          quote
          context
          date
          translatedQuote
          originalQuoteLanguage
          published
          speaker {
            id
            name
            type
            image
            inSubscription
            isConnected
            shortestName
            tooltip
          }
          industries {
            id
            name
          }
          countries {
            id
            name
          }
          createdAt
          updatedAt
          event {
            id
            title
            sources
          }
        }
        ... on FeedRegulation {
          id
          title
          legalName
          regulationType
          summary
          createdAt
          updatedAt
          date
          source
          attachment
          bullets
          jurisdiction
          issuer {
            id
            name
            type
            image
            inSubscription
            isConnected
            shortestName
            tooltip
          }
          authorizedIssuer {
            id
            name
            type
            image
            shortestName
            tooltip
          }
          industries {
            id
            name
            type
            tooltip
          }
          notesCount
          isCompliant
        }
        ... on FeedRole {
          id
          position
          type
          org
          startDate
          endDate
          source
          createdAt
          updatedAt
          entity {
            id
            name
            type
            image
            inSubscription
            isConnected
            shortestName
          }
          employer {
            id
            name
            type
            image
            inSubscription
            isConnected
            shortestName
          }
        }
        ... on FeedEducation {
          id
          degree
          specialty
          institutionText
          startDate
          endDate
          source
          createdAt
          updatedAt
          entity {
            id
            name
            type
            image
            inSubscription
            isConnected
            shortestName
          }
          institution {
            id
            name
            type
            image
            inSubscription
            isConnected
            shortestName
          }
        }
        ... on FeedAppointment {
          id
          title
          organization
          headline
          startDate
          entity {
            id
            name
            image
            type
            inSubscription
            isConnected
            shortestName
            tooltip
          }
          organizationEntity {
            id
            name
            image
            type
            inSubscription
            isConnected
            shortestName
            tooltip
          }
          createdAt
          updatedAt
          source
          event {
            id
            title
            sources
          }
        }
      }
    }
  }
`;

export default query;
