import React from 'react';

import Modal from 'react-modal';
import { modalStyles } from '../../../shared/modal';
import RemoveComply from './RemoveComply';
import MarkComply from './MarkComply';

/**
 * @argument {boolean} isOpen - Whether the modal is open or not
 * @argument {function} onClose - Function to close the modal
 * @argument {string} type - The type of the modal (REMOVE or COMPLY)
 * @argument {string} regulationId - The id of the regulation
 * @argument {string} note - The note of the regulation
 * @argument {function} onMarkCompliant - Function to mark the regulation as compliant
 * @argument {function} onRemoveCompliant - Function to remove the compliance of the regulation
 */
function ComplyRegulationModal({
  isOpen,
  onClose,
  type,
  regulationId,
  note,
  onMarkCompliant,
  onRemoveCompliant,
}) {
  return (
    <Modal
      isOpen={isOpen}
      style={modalStyles}
      contentLabel="Regulation Compliance"
      ariaHideApp={false}
      shouldCloseOnOverlayClick
      onRequestClose={onClose}
    >
      {type === 'REMOVE' && (
        <RemoveComply
          onClose={onClose}
          regulationId={regulationId}
          onRemoveCompliant={onRemoveCompliant}
        />
      )}

      {type === 'COMPLY' && (
        <MarkComply
          onClose={onClose}
          regulationId={regulationId}
          note={note}
          onMarkCompliant={onMarkCompliant}
        />
      )}
    </Modal>
  );
}

export default ComplyRegulationModal;
