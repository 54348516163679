import React from 'react';

import css from './Timeline.module.css';

import DossierCard from '../../DossierCard/DossierCard';
import useDossierEvents from '../../../../../../hooks/useDossierEvents';
import EventCard from '../../../../../Cards/EventCard/EventCard';

function Timeline({ entity, size, className }) {
  const {
    events,
    isLoading,
    totalEvents,
    totalPages,
    onNextPage,
    onPreviousPage,
    currentIndex,
  } = useDossierEvents(entity.id);

  const pagination = {
    totalItems: totalEvents,
    totalPages,
    currentIndex,
    onNextPage,
    onPreviousPage,
  };

  if (isLoading) return <LoadingState size={size} pagination={pagination} />;
  if (!events || events.length === 0) return null;

  return (
    <DossierCard
      title="Timeline"
      data-cy="timeline-facet"
      className={className}
      pagination={pagination}
    >
      <div className={css.list} data-expanded={size === 'expanded'}>
        {events.map((event) => (
          <EventCard
            event={{ ...event, industries: event.briefs }}
            key={event.id}
            size={size}
          />
        ))}
      </div>
    </DossierCard>
  );
}

export default Timeline;

function LoadingState({ size, pagination }) {
  return (
    <DossierCard title="Timeline" pagination={pagination}>
      <div className={css.container} data-expanded={size === 'expanded'}>
        <div style={{ height: 200 }} className="skeleton-v2" />
        <div style={{ height: 200 }} className="skeleton-v2" />
      </div>
    </DossierCard>
  );
}
