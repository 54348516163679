import React, { useMemo } from 'react';

import { Popover } from 'react-tiny-popover';
import css from './FilterPopUp.module.css';

const getPopupTop = (parentRef, popUpDimmensions, openAtBottom = false) => {
  if (!parentRef.current) return {};

  const [popupWidth, popupHeight] = popUpDimmensions;
  const windownHeight = window.innerHeight;
  const windownWidth = window.innerWidth;

  // parentRef position in screen
  const { right, top, left, bottom } =
    parentRef.current.getBoundingClientRect();

  let popUpTop;
  if (openAtBottom) {
    // Position directly below the parent element
    popUpTop = bottom + 10; // Add 10px padding
    const isBottomOverflowing = popUpTop + popupHeight > windownHeight;

    if (isBottomOverflowing) {
      // If overflowing bottom, position above parent instead
      popUpTop = Math.max(0, top - popupHeight - 10);
    }
  } else {
    // Original behavior - position at half available area above parent
    popUpTop = top / 2;
    const isBottomOverflowing = popUpTop + popupHeight > windownHeight;

    if (isBottomOverflowing) {
      const diff = windownHeight - (popUpTop + popupHeight);
      popUpTop = Math.max(0, popUpTop - Math.abs(diff));
    }
  }

  let leftPosition;
  if (openAtBottom) {
    leftPosition = left; // Start from parent's left edge when openAtBottom is true
  } else {
    const shouldOpenOnLeftSide = right + popupWidth > windownWidth * 0.9;
    leftPosition = right;

    if (shouldOpenOnLeftSide) {
      leftPosition = Math.max(left - popupWidth - 10, 0);
    }
  }

  const styles = {
    zIndex: 9999,
    left: `${leftPosition}px`,
    top: `${popUpTop}px`,
    marginLeft: `10px`,
    marginRight: `10px`,
  };

  return styles;
};

function FilterPopUp({
  children,
  content,
  onClickOutside,
  isPopoverOpen,
  parentRef,
  popupDimenssions,
  openAtBottom = false,
}) {
  const containerStyle = useMemo(() => {
    if (isPopoverOpen) {
      return getPopupTop(parentRef, popupDimenssions, openAtBottom);
    }
    return {};
  }, [isPopoverOpen]);

  return (
    <Popover
      padding={10}
      reposition={false}
      positions={['right']}
      isOpen={isPopoverOpen}
      containerStyle={containerStyle}
      onClickOutside={onClickOutside}
      content={
        <div
          className={css.card}
          style={{ width: popupDimenssions[0], height: popupDimenssions[1] }}
        >
          {content}
        </div>
      }
    >
      {children}
    </Popover>
  );
}

export default FilterPopUp;
