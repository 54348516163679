import { useMutation, useQuery } from '@apollo/client';

import GET_REGULATION_BY_ID from '../../queries/getRegulationById';
import GET_NOTES_FOR_REGULATIONS from '../../queries/getNotesForRegulation';
import CREATE_NOTE from '../../queries/createNote';
import GET_ALL_NOTES from '../../queries/getAllNotes';
import DELETE_NOTE from '../../queries/deleteNote';
import UPDATE_NOTE from '../../queries/updateNote';
import notify from '../../lib/notify';
import { NoteType } from './useNotes';

function useRegulation(id) {
  const { data, loading } = useQuery(GET_REGULATION_BY_ID, {
    variables: { id },
  });

  const { data: notes, loading: isLoadingNotes } = useQuery(
    GET_NOTES_FOR_REGULATIONS,
    {
      variables: { regulationId: id },
    },
  );

  const [insertNote, createNoteDetails] = useMutation(CREATE_NOTE, {
    refetchQueries: [GET_ALL_NOTES, GET_NOTES_FOR_REGULATIONS],
    onError: (error) => notify.error(error),
  });

  const [deleteNoteMutation, deleteNoteDetails] = useMutation(DELETE_NOTE, {
    refetchQueries: [GET_ALL_NOTES, GET_NOTES_FOR_REGULATIONS],
    onError: (error) => notify.error(error),
  });

  const [updateNoteMutation, updateNoteDetails] = useMutation(UPDATE_NOTE, {
    refetchQueries: [GET_ALL_NOTES, GET_NOTES_FOR_REGULATIONS],
    onError: (error) => notify.error(error),
  });

  const createNote = (regulationId, note) => {
    insertNote({
      variables: {
        targetId: regulationId,
        note,
        noteType: NoteType.regulationNotes,
      },
    });
  };

  const deleteNote = (note) => {
    return deleteNoteMutation({
      variables: {
        noteId: note.id,
        noteType: NoteType.regulationNotes,
      },
    });
  };

  const updateNote = (note) => {
    return updateNoteMutation({
      variables: {
        noteId: note.id,
        note: note.note,
        noteType: NoteType.regulationNotes,
      },
    });
  };

  return {
    regulation: data?.getRegulation?.regulation || null,
    loading,
    notes: notes?.getNotesForRegulation || [],
    createNote,
    deleteNote,
    updateNote,
    isLoadingNotes,
    isSavingNote: createNoteDetails.loading,
    isUpdatingNote: updateNoteDetails.loading,
    isDeletingNote: deleteNoteDetails.loading,
  };
}

export default useRegulation;
