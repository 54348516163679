import React, { useState } from 'react';

import { Popover } from 'react-tiny-popover';
import Card from '../../Card/Card';
import More from '../../../../../icons/More';
import css from './CompliantCard.module.css';
import IconButton from '../../../IconButton/IconButton';
import { formatDateMed } from '../../../../../lib/date';
import useNavigation from '../../../../hooks/useNavigation';
import CompliantBadge from '../../../CompliantBadge/CompliantBadge';

function CompliantCard({ regulation, onEdit, onRemoveCompliant }) {
  const [isMenuOpen, setIsMenuOpen] = useState();
  const { params, toggleRegulationDossier } = useNavigation();
  const title = regulation.summarizedName || regulation.legalName;

  const onMenuClicked = (e) => {
    e.stopPropagation();
    setIsMenuOpen(true);
  };

  const handleEdit = (reg) => {
    setIsMenuOpen(false);
    onEdit(reg);
  };

  const onCardClicked = (reg) => {
    toggleRegulationDossier(reg.id);
  };

  const isSelected = params.get('regulationId') === regulation.id;

  return (
    <Card selected={isSelected} onClick={() => onCardClicked(regulation)}>
      <div className={css.header}>
        <h3>{title}</h3>

        <Popover
          isOpen={isMenuOpen}
          positions={['bottom', 'right']}
          containerClassName={css.menu}
          onClickOutside={(e) => {
            e.stopPropagation();
            setIsMenuOpen(!isMenuOpen);
          }}
          content={
            <div>
              <div
                className={css.menuItem}
                onClick={(e) => {
                  e.stopPropagation();
                  handleEdit(regulation);
                }}
              >
                Edit note
              </div>
              <div
                className={css.menuItem}
                onClick={(e) => {
                  e.stopPropagation();
                  onRemoveCompliant(regulation);
                }}
              >
                Remove compliance
              </div>
            </div>
          }
        >
          <IconButton onClick={onMenuClicked}>
            <More />
          </IconButton>
        </Popover>
      </div>

      <p className={css.note}>{regulation.complianceNote}</p>

      <CompliantBadge />

      <div className={css.divider} />

      <div className={css.date}>
        {formatDateMed(regulation.complianceUpdatedAt)}
      </div>
    </Card>
  );
}

export default CompliantCard;
