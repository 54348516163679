import { gql } from '@apollo/client';

const getNotesForQuoteQuery = gql`
  query getNotesForQuote($quoteId: ID!) {
    getNotesForQuote(quoteId: $quoteId) {
      id
      note
      title
      source
      userName
      date
    }
  }
`;

export default getNotesForQuoteQuery;
