import React, { useState } from 'react';

import css from './CompliantRegulations.module.css';
import PanelHeader from '../PanelHeader/PanelHeader';
import CompliantCard from './CompliantCard/CompliantCard';
import useCompliRegulations from '../../../hooks/useCompliantRegulations';
import ComplyRegulationModal from '../../Modals/ComplyRegulationModal/ComplyRegulationModal';

function CompliantRegulations() {
  const [openedModal, setOpenedModal] = useState(null);
  const { data, loading, refetch } = useCompliRegulations();

  const regulations =
    data?.searchEventsAndRegulations?.regulations?.results || [];

  const onSearch = (query) => {
    refetch({
      name: query,
    });
  };

  const onEdit = (regulation) => {
    setOpenedModal({ type: 'COMPLY', context: regulation });
  };

  const onRemoveCompliant = (regulation) => {
    setOpenedModal({ type: 'REMOVE', context: regulation });
  };

  return (
    <div className={css.main}>
      <PanelHeader
        count={regulations.length}
        onSearch={onSearch}
        title="Compliant regulations"
      />
      {loading && <LoadingState />}

      {!loading && (
        <div className={css.list}>
          {regulations.map((regulation) => (
            <CompliantCard
              key={regulation.id}
              regulation={regulation}
              note={regulation.complianceNote}
              onEdit={onEdit}
              onRemoveCompliant={onRemoveCompliant}
            />
          ))}
        </div>
      )}

      <ComplyRegulationModal
        isOpen={Boolean(openedModal)}
        type={openedModal?.type}
        regulationId={openedModal?.context.id}
        note={openedModal?.context.complianceNote}
        onClose={() => setOpenedModal(null)}
        onRemoveCompliant={() => {
          setOpenedModal(null);
          refetch();
        }}
        onMarkCompliant={() => {
          setOpenedModal(null);
          refetch();
        }}
      />
    </div>
  );
}

const LoadingState = () => (
  <div className={css.loadingState}>
    <div style={{ height: 100 }} className="skeleton-v2" />
    <div style={{ height: 100 }} className="skeleton-v2" />
    <div style={{ height: 100 }} className="skeleton-v2" />
    <div style={{ height: 100 }} className="skeleton-v2" />
  </div>
);

export default CompliantRegulations;
