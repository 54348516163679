import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.933 14.667l3.034-8h1.4l3.033 8H14l-.717-2.034H10.05l-.717 2.034h-1.4zm-5.266-2l-.934-.934L5.1 8.367a7.709 7.709 0 01-1.058-1.334c-.317-.5-.609-1.066-.875-1.7h1.4c.222.434.444.811.666 1.134.222.322.49.644.8.966.367-.366.747-.88 1.142-1.541A9.735 9.735 0 008.066 4h-7.4V2.667h4.667V1.333h1.333v1.334h4.667V4H9.4c-.234.8-.584 1.622-1.05 2.467-.467.844-.928 1.489-1.383 1.933l1.6 1.633-.5 1.367-2.034-2.083-3.367 3.35zm7.8-1.2h2.4l-1.2-3.4-1.2 3.4z"
        fill="#929AA3"
      />
    </svg>
  );
}

export default SvgComponent;
