import { gql } from '@apollo/client';

const query = gql`
  query getQuoteById($quoteId: ID!) {
    getQuoteById(quoteId: $quoteId) {
      id
      quote
      context
      date
      translatedQuote
      originalQuoteLanguage
      speaker {
        id
        name
        type
        image
        inSubscription
        isConnected
        shortestName
        tooltip
      }
      industries {
        id
        name
      }
      countries {
        id
        name
      }
      createdAt
      updatedAt
      event {
        id
        title
        sources
      }
    }
  }
`;

export default query;
