import { useMutation, useQuery } from '@apollo/client';
import GET_QUOTE_BY_ID from '../../queries/getQuoteById';
import CREATE_NOTE from '../../queries/createNote';
import GET_ALL_NOTES from '../../queries/getAllNotes';
import DELETE_NOTE from '../../queries/deleteNote';
import UPDATE_NOTE from '../../queries/updateNote';
import GET_NOTES_FOR_QUOTES from '../../queries/getNotesForQuote';
import notify from '../../lib/notify';
import { NoteType } from './useNotes';

function useQuote(id) {
  const { data, loading } = useQuery(GET_QUOTE_BY_ID, {
    variables: { quoteId: id },
  });

  const notes = useQuery(GET_NOTES_FOR_QUOTES, {
    variables: { quoteId: id },
  });

  const [insertNote, createNoteDetails] = useMutation(CREATE_NOTE, {
    refetchQueries: [GET_ALL_NOTES, GET_NOTES_FOR_QUOTES],
    onError: (error) => notify.error(error),
  });

  const [deleteNoteMutation, deleteNoteDetails] = useMutation(DELETE_NOTE, {
    refetchQueries: [GET_ALL_NOTES, GET_NOTES_FOR_QUOTES],
    onError: (error) => notify.error(error),
  });

  const [updateNoteMutation, updateNoteDetails] = useMutation(UPDATE_NOTE, {
    refetchQueries: [GET_ALL_NOTES, GET_NOTES_FOR_QUOTES],
    onError: (error) => notify.error(error),
  });

  const createNote = (quoteId, note) => {
    insertNote({
      variables: {
        targetId: quoteId,
        note,
        noteType: NoteType.quoteNotes,
      },
    });
  };

  const deleteNote = (note) => {
    return deleteNoteMutation({
      variables: {
        noteId: note.id,
        noteType: NoteType.quoteNotes,
      },
    });
  };

  const updateNote = (note) => {
    return updateNoteMutation({
      variables: {
        noteId: note.id,
        note: note.note,
        noteType: NoteType.quoteNotes,
      },
    });
  };

  return {
    quote: data?.getQuoteById || null,
    notes: notes.data?.getNotesForQuote || [],
    isSavingNote: createNoteDetails.loading,
    isDeletingNote: deleteNoteDetails.loading,
    isUpdatingNote: updateNoteDetails.loading,
    loading,
    createNote,
    deleteNote,
    updateNote,
  };
}

export default useQuote;
