import React from 'react';

import css from './ComplyRegulationModal.module.css';
import Button from '../../Buttom/Buttom';
import ModalHeader from '../ModalHeader/ModalHeader';
import useToogleRegulationCompliance from '../../../hooks/useToogleRegulationCompliant';
import notify from '../../../../lib/notify';

function RemoveComply({ onClose, regulationId, onRemoveCompliant }) {
  const { tooggleCompliance, loading } = useToogleRegulationCompliance();

  const onRemove = async () => {
    try {
      await tooggleCompliance(regulationId, '', false);
      onRemoveCompliant();
    } catch (error) {
      notify.error('Failed to remove compliance');
    }
  };

  return (
    <div className={css.main}>
      <ModalHeader title="Remove 'Compliant' Mark" onClose={onClose} />
      <div className={css.message}>This will remove your compliance</div>
      <div className={css.actions}>
        <Button type="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          loading={loading}
          disabled={loading}
          type="danger"
          onClick={onRemove}
        >
          Remove Mark
        </Button>
      </div>
    </div>
  );
}

export default RemoveComply;
