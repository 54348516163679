import React from 'react';
import DossierCard from '../../DossierCard/DossierCard';

import css from './Regulations.module.css';
import useDossierRegulations from '../../../../../../hooks/useDossierRegulations';
import RegulationCard from '../../../../../Cards/RegulationCard/RegulationCard';

function Regulations({ entity, size, className }) {
  const {
    regulations,
    isLoading,
    totalRegulations,
    totalPages,
    currentIndex,
    onNextPage,
    onPreviousPage,
  } = useDossierRegulations(entity.id);

  const pagination = {
    totalItems: totalRegulations,
    totalPages,
    currentIndex,
    onNextPage,
    onPreviousPage,
  };

  if (isLoading)
    return (
      <LoadingState size={size} pagination={pagination} className={className} />
    );
  if (!regulations || regulations.length === 0) return null;

  return (
    <DossierCard
      title="Regulations"
      data-cy="regulations-facet"
      className={className}
      pagination={pagination}
    >
      <div className={css.list} data-expanded={size === 'expanded'}>
        {regulations.map((regulation) => (
          <RegulationCard
            key={regulation.id}
            regulation={regulation}
            size={size}
          />
        ))}
      </div>
    </DossierCard>
  );
}

export default Regulations;

function LoadingState({ size, pagination, className }) {
  return (
    <DossierCard
      title="Regulations"
      className={className}
      pagination={pagination}
    >
      <div className={css.container} data-expanded={size === 'expanded'}>
        <div style={{ height: 400 }} className="skeleton-v2" />
      </div>
    </DossierCard>
  );
}
