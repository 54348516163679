import { useQuery } from '@apollo/client';

import SEARCH_COMPLIANT_REGULATIONS from '../../queries/searchCompliantRegulations';

function useCompliantRegulations() {
  const { data, loading, refetch } = useQuery(SEARCH_COMPLIANT_REGULATIONS, {
    variables: {
      filterCategories: {
        regulations: {
          limit: 25,
          skip: 0,
          compliance: true,
        },
      },
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  return { data, loading, refetch };
}

export default useCompliantRegulations;
