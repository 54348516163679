import { useMutation } from '@apollo/client';
import TOGGLE_REGULATION_COMPLIANCE from '../../queries/toggleUserCompliance';

function useToogleRegulationCompliance() {
  const [updateCompliance, { loading }] = useMutation(
    TOGGLE_REGULATION_COMPLIANCE,
  );

  const tooggleCompliance = async (regulationId, note, flag) => {
    return updateCompliance({
      variables: {
        regulationId,
        note,
        flag,
      },
    });
  };

  return { tooggleCompliance, loading };
}

export default useToogleRegulationCompliance;
