import React, { useState } from 'react';
import ModalHeader from '../ModalHeader/ModalHeader';
import Button from '../../Buttom/Buttom';
import useToogleRegulationCompliance from '../../../hooks/useToogleRegulationCompliant';

import css from './ComplyRegulationModal.module.css';
import notify from '../../../../lib/notify';

function MarkComply({ onClose, regulationId, note, onMarkCompliant }) {
  const [textAreaValue, setTextAreaValue] = useState(note || '');

  const { tooggleCompliance, loading } = useToogleRegulationCompliance();

  const onSave = async () => {
    try {
      await tooggleCompliance(regulationId, textAreaValue, true);
      onMarkCompliant();
    } catch (error) {
      notify.error('Failed to mark as compliant');
    }
  };

  return (
    <div className={css.main}>
      <ModalHeader title="Mark as Compliant" onClose={onClose} />
      <textarea
        className={css.textarea}
        type="text-area"
        placeholder="Add a note about this regulation"
        onChange={(e) => setTextAreaValue(e.target.value)}
        value={textAreaValue}
      />
      <div className={css.actions}>
        <Button type="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={loading}
          loading={loading}
          type="primary"
          onClick={onSave}
        >
          Save
        </Button>
      </div>
    </div>
  );
}

export default MarkComply;
