const ENVS = {
  LOCAL: 'local',
  DEV: 'dev',
  PROD: 'production',
};

const env = process.env.REACT_APP_ENVIRONMENT;
window.env = env;

export { env, ENVS };
