import { useMutation, useQuery } from '@apollo/client';
import GET_EVENT_BY_ID from '../../queries/getEventById';
import GET_NOTES_FOR_EVENTS from '../../queries/getNotesForEvent';
import CREATE_NOTE from '../../queries/createNote';
import UPDATE_NOTE from '../../queries/updateNote';
import DELETE_NOTE from '../../queries/deleteNote';
import GET_ALL_NOTES from '../../queries/getAllNotes';
import notify from '../../lib/notify';
import { NoteType } from './useNotes';

function useEvent(id) {
  const { data, loading } = useQuery(GET_EVENT_BY_ID, {
    variables: { eventId: id },
  });

  const notes = useQuery(GET_NOTES_FOR_EVENTS, {
    variables: { eventId: id },
  });

  const [insertNote, createNoteDetails] = useMutation(CREATE_NOTE, {
    refetchQueries: [GET_ALL_NOTES, GET_NOTES_FOR_EVENTS],
    onError: (error) => notify.error(error),
  });

  const [updateNoteMutation, updateNoteDetails] = useMutation(UPDATE_NOTE, {
    refetchQueries: [GET_ALL_NOTES, GET_NOTES_FOR_EVENTS],
    onError: (error) => notify.error(error),
  });

  const [deleteNoteMutation, deleteNoteDetails] = useMutation(DELETE_NOTE, {
    refetchQueries: [GET_ALL_NOTES, GET_NOTES_FOR_EVENTS],
    onError: (error) => notify.error(error),
  });

  const createNote = (eventId, note) => {
    insertNote({
      variables: {
        targetId: eventId,
        note,
        noteType: NoteType.eventNotes,
      },
    });
  };

  const updateNote = (note) => {
    return updateNoteMutation({
      variables: {
        noteId: note.id,
        note: note.note,
        noteType: NoteType.eventNotes,
      },
    });
  };

  const deleteNote = (note) => {
    return deleteNoteMutation({
      variables: {
        noteId: note.id,
        noteType: NoteType.eventNotes,
      },
    });
  };

  return {
    event: data?.getEventById?.event || null,
    loading,
    notes: notes.data?.getNotesForEvent || [],
    isSavingNote: createNoteDetails.loading,
    isDeletingNote: deleteNoteDetails.loading,
    isUpdatingNote: updateNoteDetails.loading,
    createNote,
    updateNote,
    deleteNote,
  };
}

export default useEvent;
