import { gql } from '@apollo/client';

const query = gql`
  query searchEventsAndRegulations(
    $name: String
    $topics: [ID]
    $filterIndustries: [ID]
    $filterEntities: [ID]
    $filterCategories: FilterCategories
    $startDate: String
    $endDate: String
    $bookmarked: Boolean
    $connected: Boolean
    $notes: Boolean
  ) {
    searchEventsAndRegulations(
      name: $name
      topics: $topics
      filterIndustries: $filterIndustries
      filterEntities: $filterEntities
      filterCategories: $filterCategories
      startDate: $startDate
      endDate: $endDate
      bookmarked: $bookmarked
      connected: $connected
      notes: $notes
    ) {
      events {
        nextToken
        limit
        results {
          ... on SearchEvents {
            id
            title
            date
            sources
            summary
            bullets
            sentiment
            magnitude
            language
            entities {
              id
              name
              shortestName
              image
              type
              topicsId
              inSubscription
              isConnected
              tooltip
            }
            industries {
              id
              name
              type
              tooltip
              industryRelevance
            }
            countries {
              id
              name
              locationRelevance
            }
            notesCount
            quotes {
              id
              quote
              context
              speaker {
                id
                name
                shortestName
                image
                type
                topicsId
                inSubscription
                isConnected
                tooltip
                inSubscription
                isConnected
                tooltip
              }
            }
          }

          ... on SearchQuote {
            id
            quote
            context
            date
            translatedQuote
            originalQuoteLanguage
            speaker {
              id
              name
              shortestName
              image
              type
              inSubscription
              isConnected
              tooltip
            }
            industries {
              id
              name
            }
            countries {
              id
              name
            }
            event {
              id
              title
              sources
            }
          }

          ... on SearchAppointment {
            id
            title
            headline
            organization
            startDate
            entity {
              id
              name
              shortestName
              image
              type
              inSubscription
              isConnected
              tooltip
            }
            organizationEntity {
              id
              name
              shortestName
              image
              type
              inSubscription
              isConnected
              tooltip
            }
            createdAt
            updatedAt
            source
            notesCount
            event {
              id
              title
              sources
            }
          }
        }
      }
      regulations {
        nextToken
        limit
        results {
          id
          legalName
          regulationType
          summarizedName
          issuer {
            id
            name
            shortestName
            image
            type
            topicsId
            inSubscription
            isConnected
            tooltip
          }
          authorizedIssuer {
            id
            name
            type
            image
            shortestName
            tooltip
          }
          startDate
          endDate
          createdAt
          updatedAt
          summary
          bullets
          source
          attachment
          jurisdiction
          industries {
            id
            name
            type
            tooltip
          }
          notesCount
          isCompliant
        }
      }
    }
  }
`;

export default query;
