import { useEffect, useState } from 'react';
import GET_DOSSIER_EVENTS from '../../queries/dossierEvents';
import { useApolloClient } from '../../hooks/useApolloClient';

const PAGE_SIZE = 2;

function useDossierEvents(id) {
  const client = useApolloClient();
  const [state, setState] = useState({
    pages: {},
    isLoading: true,
    totalEvents: 0,
    totalPages: 0,
    currentIndex: 0,
    currentPage: 0,
    events: [],
  });

  const fetchPage = async (page, limit) => {
    setState((prev) => ({ ...prev, isLoading: true }));
    try {
      const { data } = await client.query({
        query: GET_DOSSIER_EVENTS,
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-first',
        variables: {
          id,
          page,
          limit,
        },
      });

      const { events, totalEvents } = data.getDossierEvents;

      setState((prev) => ({
        ...prev,
        currentPage: page,
        currentIndex: page - 1,
        totalEvents,
        totalPages: Math.ceil(totalEvents / PAGE_SIZE),
        isLoading: false,
        pages: { ...prev.pages, [page]: events },
        events,
      }));
    } catch (error) {
      setState((prev) => ({ ...prev, isLoading: false }));
    }
  };

  const onNextPage = () => {
    fetchPage(state.currentPage + 1, PAGE_SIZE);
  };

  const onPreviousPage = () => {
    setState((prev) => {
      const newPage = prev.currentPage - 1;

      return {
        ...prev,
        currentPage: newPage,
        currentIndex: newPage - 1,
        events: prev.pages[newPage],
      };
    });
  };

  useEffect(() => {
    fetchPage(state.currentPage + 1, PAGE_SIZE);
  }, []);

  return { ...state, onNextPage, onPreviousPage };
}

export default useDossierEvents;
