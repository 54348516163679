import React from 'react';
import BriefPillCloseIcon from '../../icons/BriefPillCloseIcon/BriefPillCloseIcon';
import css from './BriefPill.module.css';
import Tooltip from '../Tooltip/Tooltip';
import { env, ENVS } from '../../shared';

const getPillColor = (relevance) => {
  switch (relevance) {
    case 'CORE':
      return css.core;
    case 'SIGNIFICANT':
      return css.significant;
    case 'PERIPHERAL':
      return css.peripheral;
    default:
      return '';
  }
};

const BriefPill = ({
  className = '',
  name,
  onClose,
  tooltip,
  relevance,
  isGeography,
}) => {
  const isCloseable = typeof onClose === 'function';

  // filter out non-core/significant relevance for prod
  if (!isGeography && env === ENVS.PROD) {
    if (relevance !== 'CORE' && relevance !== 'SIGNIFICANT') {
      return null;
    }
  }

  // don't show colored pill for prod
  const relevanceColor = env === ENVS.PROD ? '' : getPillColor(relevance);

  return (
    <Tooltip text={tooltip}>
      <div
        data-cy="brief-pill"
        className={`${css.pill} ${className} ${relevanceColor}`}
        data-type="Brief"
        data-action={isCloseable ? 'close' : 'none'}
      >
        <p data-cy="brief-pill-name" className={css.name}>
          {name}
        </p>
        {isCloseable && (
          <button
            data-cy="brief-pill-close"
            type="button"
            className={css.close}
            onClick={onClose}
          >
            <BriefPillCloseIcon className={css.icon} />
          </button>
        )}
      </div>
    </Tooltip>
  );
};

export default BriefPill;
